<script>
	import store from "@/store";
	const pageName = 'terms-conditions';
	const nextPage = 'onboarding';
	export default {
		beforeRouteEnter(to, from, next) {
			if(store.getters.pageViewed(pageName)) {
				return next({name: nextPage});
			}
			next();
		},
		data() {
			return {
				checkboxValue: false,
				link: 'https://s3.eu-west-1.amazonaws.com/thecoach.canecom.com/fradi-food-web-app/terms-conditions/index.html'
			}
		},
		methods: {
			accept() {
				if(!this.checkboxValue) {
					return false;
				}
				store.dispatch('pageViewed', pageName);
				this.$router.replace({name: nextPage})
			}
		}
	}
</script>
<template>
	<div class="container flex">
		<div class="flex-1">
			<img src="../../assets/images/terms-conditions.svg"/>
		</div>
		<div class="row flex-1">
			<div class="cb-row">
				<div class="cb-container">
					<input id="checkbox" type="checkbox" v-model="checkboxValue"/>
				</div>
				<label for="checkbox" v-html="$t('terms-conditions-desc', [link])"></label>
			</div>
		</div>
		<div class="row flex-1">
			<div @click="accept" class="gold-button" :class="{disabled: !checkboxValue}">
				{{$t('button-next')}}
			</div>
		</div>
	</div>
</template>
<style scoped>
	.container {
		height: 100%;
		min-height: calc(100vh - 60px);
	}
	.flex {
		display: flex;
		flex-direction: column;
	}
	.flex-1 {
		flex: 1;
	}
	.cb-row {
		display: flex;
	}
	.cb-row .cb-container {
		margin-right: 14px;
	}
	/deep/ .link {
		color: var(--theme-color-3);
	}
	input[type="checkbox"] {
		margin: 3px 0 0;
	}
	.row {
		margin-bottom: 14px;
	}
	.gold-button {
		display: block;
	}
</style>
